<template>
  <footer class="bg-lol-purple py-8">
    <div class="container mx-auto px-4">
      <div class="flex flex-col items-center">
        <div class="mb-6">
          <img src="/images/lol-hoz30.png" alt="LOL Photo Booth" class="h-12">
        </div>
        <nav class="mb-6">
          <ul class="flex flex-wrap justify-center space-x-6">
            <li><router-link to="/" class="text-white hover:text-lol-pink">Home</router-link></li>
            <li><router-link to="/our-products" class="text-white hover:text-lol-pink">Our Products</router-link></li>
            <li><router-link to="/gallery" class="text-white hover:text-lol-pink">Gallery</router-link></li>
            <li><router-link to="/about-us" class="text-white hover:text-lol-pink">About Us</router-link></li>
            <li><router-link to="/contact-us" class="text-white hover:text-lol-pink">Contact</router-link></li>
          </ul>
        </nav>
        <div class="flex space-x-4 mb-6">
          <a href="#" class="text-white hover:text-lol-pink">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
            </svg>
          </a>
          <a href="#" class="text-white hover:text-lol-pink">
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.998 12c0-6.628-5.372-12-11.99-12C5.372 0 0 5.372 0 12c0 5.988 4.388 10.952 10.124 11.852v-8.384H7.078v-3.469h3.046V9.356c0-3.008 1.792-4.669 4.532-4.669 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z"/>
            </svg>
          </a>
        </div>
        <div class="text-white text-xs opacity-40 font-mono">v1.0.3</div>
        <div class="text-white text-xs opacity-40 mt-2">
          Website powered & designed by <a href="https://flexsolutions.com.au/" target="_blank" rel="noopener noreferrer" class="hover:text-lol-pink">Flex Solutions</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
