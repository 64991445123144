<template>
  <div class="silver-mirror-booth bg-lol-purple text-white min-h-screen">
    <main class="container mx-auto px-4 py-8">
      <h1 class="text-4xl font-bold text-center mb-8">Silver Mirror Booth</h1>

      <div class="max-w-4xl mx-auto">
        <div class="mb-8">
          <div class="flex justify-between items-center">
            <div 
              v-for="step in 5" 
              :key="step" 
              class="flex flex-col items-center cursor-pointer"
              @click="goToStep(step)"
            >
              <div :class="[
                'w-10 h-10 rounded-full flex items-center justify-center',
                currentStep >= step ? 'bg-lol-pink' : 'bg-gray-600'
              ]">
                {{ step }}
              </div>
              <span class="text-sm mt-2">Step {{ step }}</span>
            </div>
          </div>
          <div class="h-2 bg-gray-600 mt-4 rounded-full">
            <div
              class="h-full bg-lol-pink rounded-full transition-all duration-500 ease-in-out"
              :style="{ width: `${(currentStep - 1) * 25}%` }"
            ></div>
          </div>
        </div>

        <div class="bg-lol-purple bg-opacity-50 p-8 rounded-lg shadow-lg">
          <component 
            :is="currentComponent" 
            :booth-type="'Silver Mirror Booth'"
            @next-step="nextStep" 
            @prev-step="prevStep"
            @open-contact-form="showContactForm"
            :booking-data="bookingData"
            @update:booking-data="updateBookingData"
          />
        </div>

        <div class="mt-8 flex justify-between">
          <button
            v-if="currentStep > 1"
            @click="prevStep"
            class="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-full"
          >
            Previous
          </button>
          <button
            v-if="currentStep < 5"
            @click="nextStep"
            class="bg-lol-pink hover:bg-opacity-80 text-white font-bold py-2 px-4 rounded-full"
          >
            Next
          </button>
        </div>
      </div>
    </main>

    <Footer />

    <!-- 联系表单模态框 -->
    <div v-if="isContactFormVisible" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div class="bg-white p-8 rounded-lg max-w-md w-full">
        <h2 class="text-2xl font-bold mb-4 text-lol-purple">Contact Us</h2>
        <!-- 在这里添加联系表单的内容 -->
        <button @click="closeContactForm" class="mt-4 bg-lol-pink hover:bg-opacity-80 text-white font-bold py-2 px-4 rounded-full">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Step1BoothInfo from '@/components/booking/Step1BoothInfo.vue'
import Step2RentalHours from '@/components/booking/Step2RentalHours.vue'
import Step3Backdrop from '@/components/booking/Step3Backdrop.vue'
import Step4Addons from '@/components/booking/Step4Addons.vue'
import Step5Confirmation from '@/components/booking/Step5Confirmation.vue'
import enhancedSeoMixin from '@/mixins/enhancedSeoMixin'

export default {
  name: 'SilverMirrorBooth',
  components: {
    Footer,
    Step1BoothInfo,
    Step2RentalHours,
    Step3Backdrop,
    Step4Addons,
    Step5Confirmation
  },
  data() {
    return {
      currentStep: 1,
      bookingData: {
        boothType: 'Silver Mirror Booth',
        hours: 4,
        backdrop: '',
        addons: [],
        addonsTotalPrice: 0,
        customerInfo: {}
      },
      isContactFormVisible: false
    }
  },
  computed: {
    currentComponent() {
      switch (this.currentStep) {
        case 1: return 'Step1BoothInfo'
        case 2: return 'Step2RentalHours'
        case 3: return 'Step3Backdrop'
        case 4: return 'Step4Addons'
        case 5: return 'Step5Confirmation'
        default: return 'Step1BoothInfo'
      }
    }
  },
  methods: {
    nextStep() {
      if (this.currentStep < 5) {
        this.currentStep++
      }
    },
    prevStep() {
      if (this.currentStep > 1) {
        this.currentStep--
      }
    },
    goToStep(step) {
      this.currentStep = step
    },
    showContactForm() {
      this.isContactFormVisible = true
    },
    closeContactForm() {
      this.isContactFormVisible = false
    },
    updateBookingData(newData) {
      this.bookingData = { ...this.bookingData, ...newData }
    }
  },
  mixins: [enhancedSeoMixin],
  mounted() {
    this.setEnhancedMetaTags({
      title: 'Silver Mirror Photo Booth Rental Melbourne | LOL Photo Booth',
      description: 'Elegant Silver Mirror Photo Booth rental in Melbourne. Perfect for weddings, parties and corporate events. Create stunning memories with our premium mirror booth.',
      keywords: 'silver mirror booth, mirror photo booth melbourne, wedding photo booth, party photo booth, event photography melbourne, photo booth hire melbourne',
      image: '/images/silver-mirror-booth.jpg'
    })
  }
}
</script>

<style scoped>
/* 如果需要任何特定于此组件的样式，可以在这里添加 */
</style>
